import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { checkIfLiveDeal } from 'utils/checkIfLiveDeal';
import propTypes from 'prop-types';
import { useNavigate, useLocation, generatePath } from 'react-router-dom';
import { useUserDataSource } from '../../hooks/userDataSource';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import {
  RPSLayout,
  RPSModal,
  RPSToast,
} from '@rps/web-components/build/react-wrappers';
import { appMenu } from './appMenu';
import { iconNames } from '@rps/web-components/build/web-components';
import { ModalContext } from '../../contexts/Modal';
import { LoadingPopup } from './../uiComponents/LoadingPopup.jsx';
import Calculator from 'components/calculator/DefaultCalculator';
import { version } from '../../config';
import { useSafeEffect } from './../../hooks/useSafeEffect';
import { DuplicateDeal } from 'components/deals/Duplicate';
import metadata from 'buildData.json';
import { exportApiLogs } from '@odo/data/api-logs/cache';
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import { saveChanges } from 'utils/odo-migration/save';

/**
 * Found here: https://stackoverflow.com/a/71692555
 * Allows us to query elements from the shadow DOM.
 *
 * Because RP used web components basically exclusively,
 * this is the only way to find their elements via the DOM API.
 */
const querySelectorAllShadows = (selector, el = document.body) => {
  // recurse on childShadows
  const childShadows = Array.from(el.querySelectorAll('*'))
    .map(el => el.shadowRoot)
    .filter(Boolean);

  const childResults = childShadows.map(child =>
    querySelectorAllShadows(selector, child)
  );

  // fuse all results into singular, flat array
  const result = Array.from(el.querySelectorAll(selector));
  return result.concat(childResults).flat();
};

/**
 * Primary Layout Component - displays app bar, currently edited deal, user info and logout button.
 * Also provides a context for display modals.
 */
const AppContainer = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserDataSource();
  const containerRef = useRef();
  const layoutRef = useRef();
  const currentDeal = useCurrentDealSource();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const modal = useContext(ModalContext);

  const {
    canSave: canSaveCustomOptions,
    validate,
    saveActions,
    clearActions,
  } = useCustomOptionsEditorContext();

  // When location changes, reset scroll position of child container to top.
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [location]);

  const handleShowLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const handleDuplicate = () => {
    /**
     * NOTE: resetting all local changes to the deal before duplication.
     * We could've gone with a full reload from MAG, but this is easier in the RP context.
     *
     * Duplication from search is automatically gonna use the latest info, so it can be ignored.
     */
    currentDeal.deal.undoAll();
    currentDeal.update({ immediateCache: true });

    currentDeal.selectTempDeal(currentDeal.deal);

    modal.open(
      <RPSModal
        css="#modal-container { width: 95vw; max-height: 95vh; overflow: hidden; } .body { overflow: auto; } "
        popup
        opened
        confirmText="OK"
        cancelText=""
        cbCancel={modal.close}
      >
        <div slot="header">
          <h3>Duplicate Deal</h3>
        </div>
        <DuplicateDeal
          onComplete={() => {
            /**
             * NOTE: we're resetting the custom option action list before duplication
             * this will prevent any unsaved changes from interfering with the "paste" action.
             *
             * technically this is a race condition, in that we're racing to clear the previous actions
             * before any duplication actions are loaded from the custom options cache
             *
             * but in my testing so far, this always seems to win as long as it's not done within a useEffect
             *
             * TODO: implement a better solution once our contexts are less complicated
             */
            clearActions();

            // navigate to deal editor page for the new product and close the modal
            navigate(generatePath('/old/deals/editor/buyer-and-supplier'));
            modal.close();
          }}
        />
        <div slot="footer" />
      </RPSModal>
    );
  };

  const handleOldNewDeal = () => {
    currentDeal.newDeal();
    navigate('/old/deals/create');
  };

  const handleNewDeal = () => {
    navigate('/new/deals/create');
  };

  const handleMenuClick = detail => {
    const data = detail.sourceObject;
    if (data.path === '/old/deals/create') {
      currentDeal.newDeal();
    }
    if (data.path === '/calculator') {
      showCalculatorModal();
      return;
    }
    if (data && data.path) {
      navigate(data.path);
    }
  };

  const inDealEditor = location.pathname.includes('/deals/editor');

  const toolbarButtons = [];

  if (inDealEditor && (currentDeal.deal.id || currentDeal.deal.meta.id)) {
    toolbarButtons.push({
      id: 'new_editor',
      svg: iconNames.dealEdit,
      title: 'New Editor',
    });
  }

  // if inDealEditor keep the old create, else use the new create
  if (inDealEditor) {
    toolbarButtons.push({
      id: 'old_new_deal',
      svg: iconNames.dealAdd,
      title: 'Create New Deal',
    });
  } else {
    toolbarButtons.push({
      id: 'new_deal',
      svg: iconNames.dealAdd,
      title: 'Create New Deal',
    });
  }

  const { status: dealStatus } = currentDeal.deal?.getValidity || {
    status: '',
  };
  if (inDealEditor && (currentDeal.deal?.canUndo || canSaveCustomOptions)) {
    if (
      ((currentDeal.deal?.canUndo && currentDeal.deal.canUndo()) ||
        canSaveCustomOptions) &&
      (dealStatus === 'partial' || dealStatus === 'complete')
    ) {
      toolbarButtons.push({
        id: 'save_current',
        svg: iconNames.uploadSolid,
        title: 'Save Current Working Deal',
      });
    }
    if (!checkIfLiveDeal(currentDeal.deal)) {
      toolbarButtons.push({
        id: 'duplicate_current',
        svg: iconNames.copy,
        title: 'Duplicate Current Working Deal',
      });
    }
  }

  toolbarButtons.push(
    { id: 'calculator', svg: iconNames.calculator, title: 'Calculator' },
    {
      id: 'export_logs',
      svg: iconNames.log,
      title: 'Export Logs',
    },
    {
      id: 'logout',
      svg: iconNames.exit,
      title: 'Logout',
      cbClick: () => alert('Hello'),
    }
  );

  const showCalculatorModal = useCallback(() => {
    currentDeal.selectTempDeal(currentDeal.deal);
    modal.open(
      <RPSModal
        css="#modal-container { width: 95vw; max-height: 95vh; overflow: hidden; } .body { overflow: auto; } "
        popup
        opened
        confirmText="OK"
        cancelText=""
      >
        <div slot="header">
          <h5>Cost Calculator</h5>
        </div>
        <Calculator />
        <div slot="footer" />
      </RPSModal>
    );
  }, [currentDeal, modal]);

  const handleToolbarClick = detail => {
    switch (detail.buttonId) {
      case 'old_new_deal':
        handleOldNewDeal();
        break;
      case 'new_deal':
        handleNewDeal();
        break;
      case 'duplicate_current':
        handleDuplicate();
        break;
      case 'calculator':
        let url = `${process.env.REACT_APP_ODO_BASE_URL}/price_calculator`;
        if (currentDeal.deal.id) {
          url += `?deal=${currentDeal.deal.id}`;
        }
        window.open(url, '_blank');
        break;
      case 'new_editor':
        const currentPath = location.pathname.replace('/old/deals', '/deals');
        navigate(generatePath(`/new${currentPath}`));
        break;
      case 'profile':
        break;
      case 'logout':
        handleShowLogoutModal();
        break;
      case 'save_current':
        saveCurrentDeal();
        break;
      case 'export_logs':
        exportApiLogs();
        break;
      default:
        break;
    }
  };

  const saveCurrentDeal = () => {
    modal.open(
      <RPSModal
        popup
        opened
        cancelText="No"
        confirmText="Yes"
        cbCancel={modal.close}
        cbConfirm={async () => {
          saveChanges({
            currentDeal,
            canSaveCustomOptions,
            validate,
            saveActions,
            modal,
            navigate,
          });
        }}
      >
        Upload this deal to Magento?
      </RPSModal>
    );
  };

  const handleLogoClick = () => navigate('/');

  // Effect: Log user out if day has changed since login
  useSafeEffect(() => {
    const loginTimeString = user.state.loginTime;
    if (!loginTimeString) {
      user.actions.expireSession();
    }
  }, []);

  /**
   * Add V2 badge to the toolbar if we're in the RP deal editor.
   *
   * NOTE: because of how RPs layout works, we can't pass custom components for the icon,
   * so instead we're gonna use one of their SVGs and manually add a badge to it the old fashioned way.
   */
  useEffect(() => {
    // using a timeout coz there can be a small delay before the button is rendered in the toolbar
    setTimeout(() => {
      const existingBadge = querySelectorAllShadows('#rps-toolbar-v2-badge');

      // no need if we're not editing an existing deal (inside the timeout so we can remove the badge)
      if (!inDealEditor || !(currentDeal.deal.id || currentDeal.deal.meta.id)) {
        // remove the badge if we added it previously... just in case
        if (existingBadge.length > 0) {
          existingBadge[0].remove();
        }
        return;
      }

      if (existingBadge.length > 0) return;

      const rpsToolbar = querySelectorAllShadows('rps-toolbar')[0];
      if (!rpsToolbar) return;

      const toolbarContainer =
        rpsToolbar.shadowRoot.querySelector('.toolbar-container');
      if (!toolbarContainer) return;

      const newEditorButton = toolbarContainer.querySelector('#new_editor');
      if (!newEditorButton) return;

      const v2Badge = document.createElement('div');
      v2Badge.setAttribute('id', 'rps-toolbar-v2-badge');
      v2Badge.style =
        'padding: 4px 8px; margin-block: -8px; background-color: var(--odo-color-palette-blue); border-radius: 32px; width: min-content; position: absolute; top: 8px; left: 2px;';

      const v2BadgeText = document.createElement('h2');
      v2BadgeText.style =
        'font-size: 1rem; line-height: 1; letter-spacing: 0.05em; font-weight: 800; color: var(--odo-color-white); font-family: Montserrat, sans-serif; margin: 0; padding: 0; border: 0; vertical-align: baseline;';
      v2BadgeText.textContent = 'V2';

      v2Badge.appendChild(v2BadgeText);

      const wrapper = newEditorButton.parentElement;
      wrapper.style = 'position: relative;';
      wrapper.insertBefore(v2Badge, newEditorButton);
    }, 0);
  }, [currentDeal.deal.id, currentDeal.deal.meta.id, inDealEditor]);

  return (
    <RPSLayout
      innerRef={layoutRef}
      version={`${version} - ${metadata.majorVersion}.${metadata.minorVersion}.${metadata.bugfix}/c${metadata.commitRevision}`}
      logo="/assets/odo-logo.svg"
      cbClickMenu={handleMenuClick}
      cbClickLogo={handleLogoClick}
      cbClickToolbar={handleToolbarClick}
      className="hasmenu menu-overlay icon-menu"
      css=":host {display: block; width: 99%;} main section .content-scroll { display: block; padding: 1rem !important; overflow-y: hidden;} section { width: calc(100% - 64px) !important; }"
      headerTitle={
        inDealEditor && (currentDeal.deal.id || currentDeal.deal.meta.id)
          ? `Buyers Portal - ID
            ${currentDeal.deal.id || currentDeal.deal.meta.id} -
            ${currentDeal.deal.product.name}`
          : `Buyers Portal`
      }
      menuItems={appMenu}
      toolBarButtons={toolbarButtons}
    >
      <div
        slot="main"
        ref={containerRef}
        style={{ padding: 'var(--content-padding)', display: 'block' }}
      >
        {children}
      </div>
      <div slot="popup">
        {modal.modal}
        <LoadingPopup
          open={currentDeal.uploadBusy}
          message="Uploading Deal..."
        />
        {modal.toasts.length > 0 && (
          <RPSToast
            popup
            opened
            className={modal.toasts[0].classes}
            text={modal.toasts[0].message}
            cbClose={() => modal.closeToast()}
            cbExpire={() => modal.closeToast()}
            expireseconds={modal.toasts[0].expireInSeconds}
            effect={modal.toasts[0].effect}
            position={modal.toasts[0].position}
          />
        )}
      </div>
      <RPSModal
        slot="popup"
        opened={showLogoutModal}
        popup
        cbConfirm={() => {
          user.actions.logout();
          setShowLogoutModal(false);
        }}
        cbCancel={() => setShowLogoutModal(false)}
      >
        Are you sure you want to log out?
      </RPSModal>
    </RPSLayout>
  );
};

AppContainer.propTypes = {
  children: propTypes.any,
  hasWizard: propTypes.bool,
};

export default AppContainer;
